<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">
      <span>Taloyhtiö / Kiinteistö</span>
      <v-spacer></v-spacer>
      <v-btn color="info" @click="goBack()"> Takaisin </v-btn>
    </div>

    <!-- Loader -->
    <full-page-loader
      v-if="loading"
      text="Haetaan kiinteistöä/taloyhtiötä..."
      class="full-page-loader"
    ></full-page-loader>

    <v-card v-if="!loading" class="mt-2">
      <v-card-title>
        <span>{{ currentCondominium.name }}</span>
        <v-spacer></v-spacer>
        <router-link :to="`/condominium/edit/${$route.params.id}`">
          <v-btn color="primary">Muokkaa</v-btn>
        </router-link>
      </v-card-title>

      <v-container>
        <v-row dense>
          <v-col cols="12">
            <!-- TABS -->
            <v-tabs v-model="tab" color="primary" class="mb-3" fixed-tabs show-arrows>
              <v-tabs-slider color="primary"></v-tabs-slider>

              <v-tab v-for="item in permissionTabs" :key="item.text" :href="`#${item.text}`">
                <!-- <div :class="{ isPremium: item.isPremium }">{{ item.text }}</div> -->
                <div>{{ item.text }}</div>
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>

        <!-- Access control -->
        <condominium-info v-if="tab == 'Tiedot'"></condominium-info>

        <!-- Images -->
        <Images
          v-if="tab == 'Kuvat'"
          bucket="vuokranet-kohdekuvat"
          model="condominium"
          align="center"
        ></Images>

        <h3 v-if="permissionTabs.length == 0" class="text-center pb-3">Ei näytettäviä kohteita.</h3>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import CondominiumInfo from "../../components/Condominium/CondominiumInfo.vue";
import Images from "../../components/Image/Images.vue";
import { mapActions, mapState, mapMutations } from "vuex";
import FullPageLoader from "../../components/FullPageLoader.vue";

export default {
  title: "Kohteen tiedot",

  components: {
    CondominiumInfo,
    Images,
    FullPageLoader,
  },

  mixins: [mixins],

  data() {
    return {
      tab: 0,
      tabs: [
        { text: "Tiedot", resource: "condominium", permission: true },
        { text: "Kuvat", resource: "condominium", permission: "images" },
      ],
      apartment: {
        address: "",
        apartmentNumber: "",
      },
    };
  },

  computed: {
    ...mapState("condominium", ["currentCondominium", "loading"]),

    permissionTabs() {
      return this.tabs.filter((tab) => {
        if (tab.permission === true || this.isAuthorized(tab.resource, tab.permission)) {
          return true;
        }
      });
    },
  },

  methods: {
    ...mapActions("condominium", ["getOneCondominium"]),
    ...mapMutations("condominium", ["setLoading"]),
  },

  async created() {
    try {
      this.setLoading(true);
      await this.getOneCondominium(this.$route.params.id);
      this.setLoading(false);
    } catch (err) {
      this.showPopup(err, "error");
    }
  },
};
</script>

<style scoped>
.isPremium {
  color: rgb(216, 216, 216);
}
</style>
